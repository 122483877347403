import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Accordion, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserNurse, faBook, faHandshake, faUsers, faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from 'react-responsive'
import { GatsbyImage } from "gatsby-plugin-image"

const Index = ({ location }) => {

	const data = useStaticQuery(
		graphql`
			query {
				first: file(relativePath: { eq: "intro.jpg" }) {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, height: 300)
					}
				}
				second: file(relativePath: { eq: "logo/banner.png" }) {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, height: 1071, quality: 100)
					}
				}
			}
		`
	)
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1050px)' });
	console.log('isTabletOrMobile:', isTabletOrMobile)
	return (
		<Layout key={isTabletOrMobile} location={location} fluid>
			<SEO title="Accueil" />
			<div style={{ 
				backgroundImage: isTabletOrMobile ? null : `url(${data.second.childImageSharp.gatsbyImageData.images.fallback.src})`,
				paddingBottom: '10px',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
			}}>
				<h1
					style={{
						textAlign: 'center',
						textTransform: 'uppercase',
						fontSize: isTabletOrMobile ? '35px' : '50px',
						marginBottom: isTabletOrMobile ? '0px' : '25px',
						paddingTop: isTabletOrMobile ? '0px' : '45px',
						fontWeight: isTabletOrMobile ? 500 : 600,
					}}
				>
					Institut Science Infirmière
				</h1>
				<Row style={{ marginBottom: isTabletOrMobile ? '0px' : '40px' }}>
					<Col className="mt-5 mb-5" style={{ textAlign: 'center' }}>
						<GatsbyImage
							className="rounded img-center"
							alt="home-image"
							image={data.first.childImageSharp.gatsbyImageData}
						/> 
					</Col>
				</Row>
			</div>
			<Row style={{ marginLeft: '10px', marginRight: '10px' }}>
				<Col>
					<p style={{ fontSize: '18px' }}>
						<FontAwesomeIcon style={{ marginRight: '20px' }} icon={faUserNurse} size="3x" color="#328925"/>
						ISI, Institut Science Infirmière, est un ensemble d’érudits
						intéressés <strong>à la science infirmière en tant que science fondamentale</strong>,
						c’est-à-dire avec un corps unique de connaissance enraciné dans les cadres
						de références infirmiers et les théories infirmières existants qui
						continuent à être développés par des érudits infirmiers au travers d’enquêtes
						et de conceptualisation créative.
					</p>
				</Col>
			</Row>
			<hr
				style={{
					border: '0',
					height: '2px',
					backgroundImage: 'linear-gradient(to right, transparent, #328925, transparent)',
					width: '80%',
				}}
			/>
			{/* <Row>
				<Col className="text-center pb-3">
					<FontAwesomeIcon icon={faUserNurse} size="3x" color="#328925"/>
				</Col>
			</Row> */}
			<Row style={{ marginLeft: '10px', marginRight: '10px' }}>
				<Col>
					<p style={{ fontSize: '18px' }}>
						<FontAwesomeIcon style={{ marginRight: '20px' }} icon={faBook} size="3x" color="#328925"/>
						ISI est une organisation permanente à but non lucratif qui poursuit
						le but de fortifier l’entreprise et le développement d’activités
						d’investigation universitaire en science infirmière afin de contribuer à <strong>
						l’avancement de la connaissance spécifique à la discipline infirmière</strong>.
						Investigation universitaire signifie enquête rigoureuse, dont l’ensemble du
						processus scientifisant reflète une cohérence logique avec le mode
						d’investigation choisi et l’évidence d’intégrité requise, validée par
						l’obtention du mérite scientifique.
					</p>
				</Col>
			</Row>
			<hr
				style={{
					border: '0',
					height: '2px',
					backgroundImage: 'linear-gradient(to right, transparent, #328925, transparent)',
					width: '80%',
				}}
			/>
			{/* <Row>
				<Col className="text-center pb-3">
					<FontAwesomeIcon icon={faBook} size="3x" color="#328925"/>
				</Col>
			</Row> */}
			<Row style={{ marginLeft: '10px', marginRight: '10px' }}>
				<Col>
					<p style={{ fontSize: '18px' }}>
						<FontAwesomeIcon style={{ marginRight: '20px' }} icon={faHandshake} size="3x" color="#328925"/>
						La science infirmière comporte plusieurs paradigmes et comme toute
						science elle développe des connaissances qui sont partagées par d’autres
						disciplines. ISI poursuit particulièrement ses activités à partir du <strong>
						paradigme humaindevenant (Parse, 2014; 2021),</strong> « un paradigme par-delà les confins
						du métaparadigme, du paradigme simultanéité et de tous les autres
						paradigmes. […] scientifisant n’est pas statique mais une évolution vivante,
						toujours changeante alors que de nouvelles conceptualisations sont créées »
						(Parse, 2014, 2021, préface, p. X).
					</p>
				</Col>
			</Row>
			<hr
				style={{
					border: '0',
					height: '2px',
					backgroundImage: 'linear-gradient(to right, transparent, #328925, transparent)',
					width: '80%',
				}}
			/>
			{/* <Row>
				<Col className="text-center pb-3">
					<FontAwesomeIcon icon={faHandshake} size="3x" color="#328925"/>
				</Col>
			</Row> */}
			<Row style={{ marginLeft: '10px', marginRight: '10px' }}>
				<Col>
					<p style={{ fontSize: '18px' }}>
						<FontAwesomeIcon style={{ marginRight: '20px' }} icon={faUsers} size="3x" color="#328925"/>
						ISI souhaite participer au développement et à la diffusion des
						connaissances issues de la science infirmière, <strong>force d’inspiration
						contribuant à l’évolution des pratiques et à la transformation du système de
						santé,</strong> en collaboration avec l’ensemble des acteurs concernés.
					</p>
				</Col>
			</Row>
			<hr
				style={{
					border: '0',
					height: '2px',
					backgroundImage: 'linear-gradient(to right, transparent, #328925, transparent)',
					width: '80%',
				}}
			/>
			{/* <Row>
				<Col className="text-center pb-3">
					<FontAwesomeIcon icon={faUsers} size="3x" color="#328925"/>
				</Col>
			</Row> */}
			<Row style={{ marginLeft: '10px', marginRight: '10px' }}>
				<Col>
					<p style={{ fontSize: '18px' }}>
						<FontAwesomeIcon style={{ marginRight: '20px' }} icon={faGlobeEurope} size="3x" color="#328925"/>
						ISI encourage les infirmières et infirmiers érudit-e-s intéressé-e-s
						au venant-à-connaître en scientifisant, <strong>au niveau helvétique, européen et
						international.</strong>
					</p>
				</Col>
			</Row>
		</Layout>
	)
}

export default Index


